.section-header {
    text-align: center;
    margin: 0 auto;
    padding: 60px 20px 40px;
    max-width: 1200px;
    width: 100%;
    box-sizing: border-box;
    transform: translateX(-20px); /* Adjust this value to move it to the left */
  }
  
  .section-title {
    font-size: 48px;
    font-weight: 900;
    background: linear-gradient(90deg, #00FFAB, #007BFF); /* Gradient for the title */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
  }
  
  .section-description {
    font-size: 20px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.75);
    max-width: 750px;
    margin: 0 auto;
    line-height: 1.8;
  }
  
  /* Mobile adjustments */
  @media (max-width: 768px) {
    .section-header {
      transform: none; /* Center for mobile views */
      padding: 40px 20px;
    }
  
    .section-title {
      font-size: 36px;
    }
  
    .section-description {
      font-size: 18px;
    }
  }
  