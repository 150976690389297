.footer-section {
    background-color: #020c1b; /* Darker background */
    color: white;
    padding: 40px 0;
    margin-top: 60px;
  }
  
  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .footer-left {
    flex: 1;
    padding-right: 40px;
    text-align: left;
  }
  
  .footer-logo {
    font-size: 28px;
    font-weight: 700;
    color: #00FFAB;
    margin-bottom: 15px;
  }
  
  .footer-description {
    font-size: 16px;
    color: #B0B0B0;
  }
  
  .footer-right {
    flex: 1;
    text-align: right;
  }
  
  .footer-address,
  .footer-email,
  .footer-phone {
    font-size: 14px;
    color: #B0B0B0;
    margin-bottom: 10px;
  }
  
  .footer-address a,
  .footer-email a {
    color: #00FFAB;
    text-decoration: none;
  }
  
  .footer-address a:hover,
  .footer-email a:hover {
    text-decoration: underline;
  }
  
  .footer-links {
    font-size: 14px;
    color: #B0B0B0;
  }
  
  .footer-links a {
    color: #B0B0B0;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    color: #00FFAB;
  }
  
  .footer-bottom {
    text-align: center;
    font-size: 14px;
    color: #B0B0B0;
    margin-top: 30px;
  }
  
  .footer-bottom p {
    margin: 0;
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-left, .footer-right {
      text-align: center;
      margin-bottom: 20px;
    }
  }