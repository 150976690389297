.contact-form-section {
  padding-top: 120px;
}

.contact-form-container {
  padding: 60px 40px;
  background-color: #0A192F;
  color: white;
  border-radius: 10px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  margin: 0 auto;
}

.contact-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
  color: #00FFAB;
}

.contact-input,
.contact-textarea {
  margin-bottom: 20px;
  width: 100%;
}

.contact-input input,
.contact-textarea textarea {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  padding: 15px;
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.contact-input input::placeholder,
.contact-textarea textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.contact-textarea textarea {
  min-height: 150px;
  resize: vertical;
}

.contact-button {
  background-color: #00FFAB;
  color: #0A192F;
  font-weight: 600;
  padding: 12px 24px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-button:hover {
  background-color: #00D8FF;
  transform: translateY(-2px);
}

.contact-button:active {
  transform: translateY(0);
}
/* Notification Bubble Styles */
.notification-bubble {
  position: fixed;
  bottom: 20px; /* Move it to the bottom of the screen */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 15px 30px;
  border-radius: 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: 500;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  z-index: 1000; /* Make sure it's above other elements */
}

.notification-bubble.visible {
  opacity: 1;
  visibility: visible;
  animation: slideIn 0.5s ease, fadeOut 3s ease 2.5s forwards;
}

.notification-bubble.success {
  background-color: #4BB543;
}

.notification-bubble.error {
  background-color: #E74C3C;
}

@keyframes slideIn {
  0% {
    transform: translateX(-50%) translateY(50px);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.spinner {
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-top: 3px solid white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 0.6s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}




/* Mobile Adjustments */
@media (max-width: 768px) {
  .contact-form-container {
    padding: 40px 20px;
  }

  .contact-title {
    font-size: 28px;
  }

  .contact-button {
    width: auto;
  }
}
