/* About Me Section CSS */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

.about-me-section {
  padding: 80px 0;
  background-color: #0A192F;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
}

.about-me-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
}

.about-me-image {
  flex: 1;
  max-width: 200px;
  margin-bottom: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-me-image img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-me-image img:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
}

.about-me-content {
  flex: 2;
  max-width: 600px;
  text-align: center;
  margin-left: 0;
}

.about-me-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #FFFFFF;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.about-me-description {
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #B0B0B0;
  font-weight: 400;
}

.about-me-button {
  background-color: #00FFAB;
  color: #0A192F;
  border: none;
  padding: 14px 28px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: inline-block;
  margin: 20px 0;
  font-family: 'Poppins', sans-serif;
}

.about-me-button:hover {
  background-color: #00FF80;
  transform: translateY(-3px);
}

/* Desktop Adjustments: Nudge the About Me section to the left */
@media (min-width: 769px) {
  .about-me-section {
    margin-left: -80px; /* Adjust this value as needed */
  }
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .about-me-container {
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin: 0 auto;
  }

  .about-me-content {
    margin: 0 auto;
    max-width: 100%;
    text-align: center;
  }

  .about-me-image {
    max-width: 150px;
    margin-bottom: 20px;
  }

  .about-me-title {
    font-size: 28px;
  }
}
