.features-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 0 40px;
  }
  
  .features-card {
    flex: 1;
    min-width: 270px;
    max-width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: none;
    border-radius: 20px;
    padding: 25px;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background: linear-gradient(180deg, rgba(11, 25, 46, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  }
  
  .features-card:hover {
    transform: translateY(-8px);
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
  }
  
  .card-content {
    width: 100%;
  }
  
  .card-title {
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
    position: relative;
    padding-bottom: 10px;
  }
  
  .card-title::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: #00FFAB;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  
  .card-description {
    font-size: 16px;
    color: #B0B0B0;
    margin-top: 12px;
  }
  
  /* Mobile Adjustments */
  @media (max-width: 1024px) {
    .features-card {
      min-width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 768px) {
    .features-card {
      min-width: 100%;
    }
  }
  